<template>
  <div class="p-content">
    <!-- <div class="c-section c-section--mb12">
      <MileStatus :customer-data="customerData" />
    </div> -->

    <div class="c-section">
      <ContentNotices />
    </div>

    <div v-if="isShowDpBanner" class="c-section c-section--mb24">
      <Banner
        :href="
          $customUrlScheme.page('standalone', 'url=/drip-pod/introduction')
        "
        src="/assets/img/banner_drippod01.webp"
        alt="DRIP POD YOUBIアプリはこちら" />
    </div>

    <template v-if="contents && contents.length">
      <div class="u-mb24" v-for="content in contents" :key="content.index">
        <div
          v-if="content['banner_area'] && content['banner_area'].length"
          class="c-section c-section--mb24">
          <Banner
            type="button"
            :href="false"
            :src="content['banner_area'][0]['image_url']"
            :title="content['banner_area'][0]['media']"
            :alt="content['banner_area'][0]['title']"
            :tag="content['banner_area'][0]['tag']"
            @click="
              onClickContentLink(
                content['banner_area'][0]['link_url'],
                content['banner_area'][0]['event_id']
              )
            " />
        </div>

        <div
          v-if="content['big_area'] && content['big_area'].length"
          class="p-content__section-l c-section">
          <Carousel>
            <ContentCarouselCard
              v-for="item in content['big_area']"
              :key="item.index"
              :src="item['image_url']"
              :title="item['title']"
              :subtitle="item['subtitle']"
              :tag="item['tag']"
              :reward="item['reward']"
              class="swiper-slide"
              @click="onClickContentLink(item['link_url'], item['event_id'])" />
          </Carousel>
        </div>

        <div
          v-if="content['pro_coffee_area'] && content['pro_coffee_area'].length"
          class="p-content__section-l c-section">
          <h2 class="c-title2 c-title2--mb8">
            {{ WORDS.CONTENTS_TOP.TITLE_PRO_COFFEE }}
          </h2>
          <ContentCard
            v-for="item in content['pro_coffee_area']"
            :key="item.index"
            :href="
              $customUrlScheme.page(
                `detail`,
                `url=/coffee/detail/${item['id']}`
              )
            "
            :src="item['image_url']"
            :title="item['name']"
            :subtitle="item['description']"
            :reward="item['reward']"
            :pros="item['baristas']" />
        </div>

        <div
          v-if="content['movie_area'] && content['movie_area'].length"
          class="p-content__video c-section c-section--fluid c-section--white01Bg">
          <div class="c-section__inner">
            <h2 class="c-title2 c-title2--mb8">
              {{ WORDS.CONTENTS_TOP.TITLE_VIDEO }}
            </h2>

            <ProDetailVideo
              v-for="(item, index) in content['movie_area']"
              :key="index"
              :content="item"
              :index="index" />
          </div>
        </div>

        <div
          v-if="
            content['coffee_tips_area'] && content['coffee_tips_area'].length
          "
          class="p-content__section-m c-section">
          <h2 class="c-title2 c-title2--mb8">
            {{ WORDS.CONTENTS_TOP.TITLE_COFFEE_TIPS }}
          </h2>

          <div class="c-cards c-cards--2cols">
            <Content2ColCard
              v-for="item in content['coffee_tips_area']"
              :key="item['id']"
              :src="item['image_url']"
              :title="item['title']"
              :subtitle="item['subtitle']"
              :tag="item['tag']"
              :reward="item['reward']"
              @click="onClickContentLink(item['link_url'], item['event_id'])" />
          </div>
        </div>

        <div
          v-if="content['middle_area'] && content['middle_area'].length"
          class="p-content__section-m c-section">
          <h2 class="c-title2 c-title2--mb8">
            {{ WORDS.CONTENTS_TOP.TITLE_PICK_UP }}
          </h2>

          <div class="c-cards c-cards--2cols">
            <Content2ColCard
              v-for="item in content['middle_area']"
              :key="item['id']"
              :src="item['image_url']"
              :title="item['title']"
              :subtitle="item['subtitle']"
              :tag="item['tag']"
              :reward="item['reward']"
              @click="onClickContentLink(item['link_url'], item['event_id'])" />
          </div>
        </div>

        <div v-if="content['small_area'] && content['small_area'].length">
          <ContentTileCard
            v-for="item in content['small_area']"
            :key="item['id']"
            :src="item['image_url']"
            :title="item['title']"
            :subtitle="item['subtitle']"
            :tag="item['tag']"
            :reward="item['reward']"
            tiled
            @click="onClickContentLink(item['link_url'], item['event_id'])" />
        </div>

        <Empty
          v-if="
            !content['banner_area'] &&
            !content['banner_area'].length &&
            !content['big_area'] &&
            !content['big_area'].length &&
            !content['pro_coffee_area'] &&
            !content['pro_coffee_area'].length &&
            !content['movie_area'] &&
            !content['movie_area'].length &&
            !content['coffee_tips_area'] &&
            !content['coffee_tips_area'].length &&
            !content['middle_area'] &&
            !content['middle_area'].length &&
            !content['small_area'] &&
            !content['small_area'].length
          " />
      </div>
    </template>
  </div>
</template>

<script>
import { useContentLink } from '@/composables/useContentLink';
import { WORDS } from '@/constants/words';
import { SystemDialogMessage } from '@/constants/enums';
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  onBeforeUnmount
} from '@vue/composition-api';
export default defineComponent({
  components: {
    ContentNotices: () => import('@/components/content/ContentNotices.vue'),
    ContentCarouselCard: () =>
      import('@/components/content/ContentCarouselCard.vue'),

    Content2ColCard: () => import('@/components/content/Content2ColCard.vue'),
    ContentTileCard: () => import('@/components/content/ContentTileCard.vue'),

    ContentCard: () => import('@/components/content/ContentCard.vue'),
    // MileStatus: () => import('@/components/content/MileStatus.vue'),
    ProDetailVideo: () => import('@/components/pro/ProDetailVideo.vue')
  },

  setup: (props, context) => {
    const { getContentLink, isAppLink, sendToGaClickLink } = useContentLink();
    const contents = ref([]);
    const customerData = ref(undefined);
    const nextPageUrl = ref(null);
    const prevPageUrl = ref(null);
    const scrolledToBottom = ref(false);

    // NOTE: インセンティブの付与は保留
    // const plusMileage = ref(0);
    // const isStageUp = ref(false);
    // const nowMileage = ref(0);
    // const hasPresent = ref(false);
    // const stageNameBefore = ref(null);
    // const stageNameAfter = ref(null);
    // const stageNameNext = ref(null);

    /**
     * DRIP POD本体と連携済みか
     * NOTE: 初期読み込み時にバナーを表示させないため、デフォルトをtrueに設定
     */
    const isDpLink = ref(true);

    /**
     * DRIP PODのバナーを表示させる対象か
     * NOTE: DP本体と連携済みのアカウントでない場合にバナーを表示する
     */
    const isShowDpBanner = computed(() => !isDpLink.value || false);

    const fetchContentsList = async () => {
      try {
        context.root.$_loading_start();

        const { data } = await context.root
          .$repositories('contentTop')
          .get(context.root.$utilities.getUrlSearch(nextPageUrl.value));
        contents.value.push(data.data);
        prevPageUrl.value = data.prev_page_url;
        nextPageUrl.value = data.next_page_url;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
      }
    };

    /**
     * 画面下部までスクロールしたときに次のセクション分のコンテンツを取得する
     */
    const scroll = () => {
      const isBottomOfWindow =
        Math.ceil(window.pageYOffset) + window.innerHeight >=
        document.documentElement.offsetHeight;

      if (isBottomOfWindow) {
        scrolledToBottom.value = true;
        loadMore();
      }
    };

    const loadMore = async () => {
      if (context.root.$_loading_state || !nextPageUrl.value) return;
      await fetchContentsList();
    };

    /**
     * コンテンツ押下時の処理
     * @param {string} contentUrl
     * @param {number} eventId
     */
    const onClickContentLink = async (contentUrl, eventId) => {
      // NOTE: GTMのトラッキング（アプリ内の遷移は除外）
      if (!isAppLink(contentUrl)) {
        await sendToGaClickLink('click_content_top_external_link', contentUrl);
      }

      // NOTE: インセンティブの付与は保留
      // const hasAlreadyRead = !eventId && !!contentUrl && contentUrl !== null;
      // if (hasAlreadyRead) {
      //   await alreadyRead(contentUrl);
      // }

      const url = await getContentLink(contentUrl, eventId);
      if (url) window.location.href = url;

      // NOTE: インセンティブの付与は保留
      // if (hasAlreadyRead) {
      //   context.root.$contentList.presentIncentive(
      //     plusMileage.value,
      //     isStageUp.value,
      //     nowMileage.value,
      //     hasPresent.value,
      //     stageNameBefore.value,
      //     stageNameAfter.value,
      //     stageNameNext.value
      //   );
      // }
    };

    /**
     * 既読処理
     * @param {string} contentUrl
     */
    // const alreadyRead = async (contentUrl) => {
    //   try {
    //     context.root.$_loading_start();

    //     const { data } = await context.root
    //       .$repositories('contentAlreadyRead')
    //       .post({
    //         url: contentUrl
    //       });

    //     plusMileage.value = data?.mileage['plus_mileage'];
    //     isStageUp.value = data?.mileage['rank_up'];
    //     nowMileage.value = data?.mileage['now_mileage'];
    //     hasPresent.value = !!data?.present[0];
    //     stageNameBefore.value = data.mileage.stage?.before.name;
    //     stageNameAfter.value = data.mileage.stage?.after.name;
    //     stageNameNext.value = data.mileage.stage?.next.name;
    //   } catch {
    //     // noop
    //   } finally {
    //     context.root.$_loading_stop();
    //   }
    // };

    /**
     * ユーザの情報を取得
     */
    const fetchCustomerData = async () => {
      try {
        context.root.$_loading_start();
        const { data } = await context.root
          .$repositories('customer')
          .getCustomer();
        customerData.value = data;
      } catch {
        window.location.href = context.root.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        context.root.$_loading_stop();
        isDpLink.value = customerData.value?.dp_link || false;
      }
    };

    onBeforeMount(async () => {
      fetchCustomerData();
      await fetchContentsList();
      window.addEventListener('scroll', scroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scroll);
    });

    return {
      WORDS,
      contents,
      prevPageUrl,
      onClickContentLink,
      isShowDpBanner,
      customerData
    };
  }
});
</script>

<style lang="scss" scoped>
.p-content__video::v-deep {
  margin-top: 24px;

  .c-title2 {
    & + div {
      .c-coffee-channel-card {
        border-top: none;

        a {
          padding-top: 0;
        }
      }
    }
  }
}
</style>
