var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-content" },
    [
      _c("div", { staticClass: "c-section" }, [_c("ContentNotices")], 1),
      _vm.isShowDpBanner
        ? _c(
            "div",
            { staticClass: "c-section c-section--mb24" },
            [
              _c("Banner", {
                attrs: {
                  href: _vm.$customUrlScheme.page(
                    "standalone",
                    "url=/drip-pod/introduction"
                  ),
                  src: "/assets/img/banner_drippod01.webp",
                  alt: "DRIP POD YOUBIアプリはこちら",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.contents && _vm.contents.length
        ? _vm._l(_vm.contents, function (content) {
            return _c(
              "div",
              { key: content.index, staticClass: "u-mb24" },
              [
                content["banner_area"] && content["banner_area"].length
                  ? _c(
                      "div",
                      { staticClass: "c-section c-section--mb24" },
                      [
                        _c("Banner", {
                          attrs: {
                            type: "button",
                            href: false,
                            src: content["banner_area"][0]["image_url"],
                            title: content["banner_area"][0]["media"],
                            alt: content["banner_area"][0]["title"],
                            tag: content["banner_area"][0]["tag"],
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickContentLink(
                                content["banner_area"][0]["link_url"],
                                content["banner_area"][0]["event_id"]
                              )
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                content["big_area"] && content["big_area"].length
                  ? _c(
                      "div",
                      { staticClass: "p-content__section-l c-section" },
                      [
                        _c(
                          "Carousel",
                          _vm._l(content["big_area"], function (item) {
                            return _c("ContentCarouselCard", {
                              key: item.index,
                              staticClass: "swiper-slide",
                              attrs: {
                                src: item["image_url"],
                                title: item["title"],
                                subtitle: item["subtitle"],
                                tag: item["tag"],
                                reward: item["reward"],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickContentLink(
                                    item["link_url"],
                                    item["event_id"]
                                  )
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                content["pro_coffee_area"] && content["pro_coffee_area"].length
                  ? _c(
                      "div",
                      { staticClass: "p-content__section-l c-section" },
                      [
                        _c("h2", { staticClass: "c-title2 c-title2--mb8" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.WORDS.CONTENTS_TOP.TITLE_PRO_COFFEE) +
                              " "
                          ),
                        ]),
                        _vm._l(content["pro_coffee_area"], function (item) {
                          return _c("ContentCard", {
                            key: item.index,
                            attrs: {
                              href: _vm.$customUrlScheme.page(
                                "detail",
                                "url=/coffee/detail/" + item["id"]
                              ),
                              src: item["image_url"],
                              title: item["name"],
                              subtitle: item["description"],
                              reward: item["reward"],
                              pros: item["baristas"],
                            },
                          })
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                content["movie_area"] && content["movie_area"].length
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "p-content__video c-section c-section--fluid c-section--white01Bg",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "c-section__inner" },
                          [
                            _c(
                              "h2",
                              { staticClass: "c-title2 c-title2--mb8" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.WORDS.CONTENTS_TOP.TITLE_VIDEO) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._l(
                              content["movie_area"],
                              function (item, index) {
                                return _c("ProDetailVideo", {
                                  key: index,
                                  attrs: { content: item, index: index },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  : _vm._e(),
                content["coffee_tips_area"] &&
                content["coffee_tips_area"].length
                  ? _c(
                      "div",
                      { staticClass: "p-content__section-m c-section" },
                      [
                        _c("h2", { staticClass: "c-title2 c-title2--mb8" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.WORDS.CONTENTS_TOP.TITLE_COFFEE_TIPS) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "c-cards c-cards--2cols" },
                          _vm._l(content["coffee_tips_area"], function (item) {
                            return _c("Content2ColCard", {
                              key: item["id"],
                              attrs: {
                                src: item["image_url"],
                                title: item["title"],
                                subtitle: item["subtitle"],
                                tag: item["tag"],
                                reward: item["reward"],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickContentLink(
                                    item["link_url"],
                                    item["event_id"]
                                  )
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                content["middle_area"] && content["middle_area"].length
                  ? _c(
                      "div",
                      { staticClass: "p-content__section-m c-section" },
                      [
                        _c("h2", { staticClass: "c-title2 c-title2--mb8" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.WORDS.CONTENTS_TOP.TITLE_PICK_UP) +
                              " "
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "c-cards c-cards--2cols" },
                          _vm._l(content["middle_area"], function (item) {
                            return _c("Content2ColCard", {
                              key: item["id"],
                              attrs: {
                                src: item["image_url"],
                                title: item["title"],
                                subtitle: item["subtitle"],
                                tag: item["tag"],
                                reward: item["reward"],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickContentLink(
                                    item["link_url"],
                                    item["event_id"]
                                  )
                                },
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                content["small_area"] && content["small_area"].length
                  ? _c(
                      "div",
                      _vm._l(content["small_area"], function (item) {
                        return _c("ContentTileCard", {
                          key: item["id"],
                          attrs: {
                            src: item["image_url"],
                            title: item["title"],
                            subtitle: item["subtitle"],
                            tag: item["tag"],
                            reward: item["reward"],
                            tiled: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onClickContentLink(
                                item["link_url"],
                                item["event_id"]
                              )
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                !content["banner_area"] &&
                !content["banner_area"].length &&
                !content["big_area"] &&
                !content["big_area"].length &&
                !content["pro_coffee_area"] &&
                !content["pro_coffee_area"].length &&
                !content["movie_area"] &&
                !content["movie_area"].length &&
                !content["coffee_tips_area"] &&
                !content["coffee_tips_area"].length &&
                !content["middle_area"] &&
                !content["middle_area"].length &&
                !content["small_area"] &&
                !content["small_area"].length
                  ? _c("Empty")
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }